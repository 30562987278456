<template>
  <view-card-template :loaded="loaded">
    
    <template #cardTitle>
      Stat Groups
    </template>

    <template #cardBody>
      <div> <!-- Enclosing element is required to make the expanding table footer look right -->
        <v-row class="mx-1 mb-3">
          <v-col cols="4">
            <v-text-field
              v-model="nameSearch"
              label="Filter by Name"
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="selectedGlobalSportId"
              :items="globalSports"
              item-text="name"
              item-value="globalSportId"
              label="Filter by Sport"
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="4">
            <div
              class="d-flex align-center pt-2"
              style="height: 100%;"
            >
              <v-btn
                @click="openCreateModal"
                color="primary"
                hide-details
                block
              >
                Create Stat Group
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="statGroupsFiltered"
          :items-per-page="-1"
          class="expanding-v-data-table"
          dense
        >

          <template v-slot:[`item.name`]="{ item }">
            <td class="font-weight-medium text-center">
              {{ item.name }}
            </td>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <td>
              <div class="d-flex justify-center align-center">
                <!-- Edit -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="openEditModal(item)"
                      color="blue darken-2"
                      icon
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Edit Stat Group
                </v-tooltip>
                <!-- Stats -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :to="`/support/stat-group/${item.id}/stats`"
                      color="green darken-2"
                      icon
                    >
                      <v-icon small>mdi-chart-bar</v-icon>
                    </v-btn>
                  </template>
                  Manage Stats
                </v-tooltip>
              </div>
            </td>
          </template>

          <template #footer="{ props }">
            <v-divider v-if="props.pagination.itemsLength > 0"/>
            <div class="expanding-v-data-table--footer"></div>
          </template>
        </v-data-table> 
      </div>

      <app-modal
        v-if="modal"
        :value="modal"
        :persistent="true"
        :header-text="modalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <v-form ref="form">
            <v-row
              class="mt-3"
              no-gutters
            >
              <!-- Name -->
              <v-col cols="6">
                <div class="mx-2">
                  <v-text-field
                    v-model="modalStatGroup.name"
                    label="Name"
                    :rules="[
                      (input) => { return (input != null && input.length > 0) || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
              <!-- Sport -->
              <v-col cols="6">
                <div class="mx-2">
                  <v-select
                    v-model="modalStatGroup.globalSportId"
                    :items="globalSportsComplete"
                    item-text="name"
                    item-value="globalSportId"
                    label="Sport"
                    :rules="[
                      (input) => { return input != null || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitStatGroup()"
            :loading="submitStatGroupLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Submit
          </v-btn>
        </template>
      </app-modal>

    </template>
  </view-card-template>
</template>

<script>
import AppModal from "@/components/app/AppModal"
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"
import GlobalSports from "@/data/GlobalSports"

export default {
  name: "StatGroupManagement",

  components: {
    AppModal,
    ViewCardTemplate,
  },

  props: {
    //
  },

  data: () => ({
    loaded: false,

    nameSearch: "",

    selectedGlobalSportId: null,
    globalSports: [],
    globalSportsComplete: [],

    modal: false,
    modalHeaderText: "Create Stat Group",
    modalStatGroup: null,
    creating: false,

    submitStatGroupLoading: false,
    
    statGroups: [],

    headers: [
      {
        text: "Name",
        align: "center",
        width: "33%",
        sortable: false,
        value: "name",
      },
      {
        text: "Sport",
        align: "center",
        width: "33%",
        sortable: false,
        value: "sport.name",
      },
      {
        text: "Actions",
        align: "center",
        width: "33%",
        sortable: false,
        value: "actions",
      },
    ]
  }),

  computed: {
    statGroupsFiltered() {
      return this.statGroups
        .filter(at => this.nameSearch == null || at.name.toLocaleLowerCase().includes(this.nameSearch.toLocaleLowerCase()))
        .filter(at => this.selectedGlobalSportId == null || at.globalSportId == this.selectedGlobalSportId)
    }
  },

  methods: {
    openCreateModal() {
      this.modalStatGroup = {
        id: -1,
        name: null,
        globalSportId: null,
      }

      this.creating = true
      this.modalHeaderText = "Create Stat Group"
      this.modal = true
    },

    openEditModal(statGroup) {
      this.modalStatGroup = {
        id: statGroup.id,
        name: statGroup.name,
        globalSportId: statGroup.globalSportId,
      }

      this.creating = false
      this.modalHeaderText = "Edit Stat Group"
      this.modal = true
    },

    cancelModal() {
      this.modalStatGroup = null
      this.modal = false
    },

    async submitStatGroup() {
      if(this.submitStatGroupLoading) return
      if(!this.$refs.form.validate()) return
      this.submitStatGroupLoading = true

      if(this.creating) {
        const res = await Controllers.StatGroupController.SupportCreateStatGroup(this.modalStatGroup)
        if(res && !res.hasError) {
          const statGroup = res.data
          if(statGroup.globalSportId == -1) statGroup.sport = { globalSportId: -1, name: "Hidden" }
          else statGroup.sport = GlobalSports.find(s => s.globalSportId == statGroup.globalSportId)
          this.statGroups.push(statGroup)
          this.$root.showSnackbar('Stat Group created successfully!', "success", 5000)
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to create Stat Group.', "error", 5000)
        }
      }
      else {
        const res = await Controllers.StatGroupController.SupportEditStatGroup(this.modalStatGroup)
        if(res && !res.hasError) {
          const statGroup = res.data
          const listStatGroup = this.statGroups.find(at => at.id == statGroup.id)
          listStatGroup.name = statGroup.name
          listStatGroup.globalSportId = statGroup.globalSportId
          if(statGroup.globalSportId == -1) listStatGroup.sport = { globalSportId: -1, name: "Hidden" }
          else listStatGroup.sport = GlobalSports.find(s => s.globalSportId == statGroup.globalSportId)
          this.$root.showSnackbar('Stat Group edited successfully!', "success", 5000)
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to edit Stat Group.', "error", 5000)
        }
      }

      this.submitStatGroupLoading = false
      this.cancelModal()
    },
  },

  async created() {
    this.$root.updateTitle("Stat Groups")

    // Pre-fetch stat masks. (Since they aren't sport specific.)
    const res = await Controllers.StatGroupController.SupportListStatGroups()
    if(res && !res.hasError) {
      const statGroups = res.data
      
      const sportNamesByGlobalSportId = {}
      statGroups.forEach(sg => {
        if(sg.globalSportId == -1) {
          // Hidden
          sg.sport = { globalSportId: -1, name: "Hidden" }
        }
        else {
          // Standard Case
          sg.sport = GlobalSports.find(s => s.globalSportId == sg.globalSportId)
          sportNamesByGlobalSportId[sg.globalSportId] = sg.sport
        }
      })
      this.globalSports = Object.values(sportNamesByGlobalSportId).sort((a, b) => a.globalSportId - b.globalSportId)
      this.globalSports.unshift({ globalSportId: -1, name: "Hidden" })

      this.globalSportsComplete =
        [{ globalSportId: -1, name: "Hidden" }, ...GlobalSports]
        .sort((a, b) => a.globalSportId - b.globalSportId)

      this.statGroups = statGroups.sort((a, b) => a.globalSportId - b.globalSportId)
    }
    else {
      // Error handling
    }

    this.loaded = true
  },
}
</script>

<style>

</style>